<script>
  // # # # # # # # # # # # # #
  //
  //  LOGO
  //
  // # # # # # # # # # # # # #

  // *** PROPS
  export let white = true;
  export let black = false;
</script>

<style lang="scss">
  @import "../variables.scss";

  .logo {
    pointer-events: none;
    height: 100%;

    @include screen-size("small") {
      width: 100%;
      margin-left: auto;
      margin-right: auto;
      display: block;
    }

    path {
      fill: $white;
    }

    &.white {
      path {
        fill: $white;
      }
    }

    &.black {
      path {
        fill: black !important;
      }
    }
  }
</style>

<svg
  class="logo"
  class:white
  class:black
  version="1.1"
  id="Layer_1"
  xmlns="http://www.w3.org/2000/svg"
  xmlns:xlink="http://www.w3.org/1999/xlink"
  x="0px"
  y="0px"
  viewBox="0 0 67.3 16.8"
  style="enable-background:new 0 0 67.3 16.8;"
  xml:space="preserve">
  <path
    d="M18.2,1.5c0-0.6-0.5-0.8-1.2-0.8c-3.4,0.1-5.4,4.3-5.5,7.9c-0.9-2.5-1.5-5.1-1.8-6.4C9.6,1.7,9.6,1.3,8.9,1.3
    c-1.7,0-4.7-0.2-5.5,1.9C3,4.2,4.6,4.5,4.6,3.2c-0.1-1.4,1.3-1.6,2-1.6c0.9,0,1.3,0.1,1.2,1.7C7.5,6.1,6,10.8,3,10.8
    c-1.1,0-1.4-0.7-1-1.1c0.2-0.2,0.3-0.3,0.3-0.6c0-0.4-0.3-0.6-0.6-0.6c-1.2,0-1.2,2.6,1.1,2.6c4.3,0,5.5-6.3,5.8-8.9l0.1,0
    c1.4,5.4,2.9,12.6,9.2,12.6c2.9,0,3-1.8,1.9-1.8c-0.8,0-0.9,1.2-2.4,1.4c-2.2,0.2-3.8-1.8-5-4.3C11.3,8,13,1.6,16,1.8
    c0.5,0,0.9,0.2,1.3,0.3C17.6,2.1,18.2,1.9,18.2,1.5
    M16.8,11.9c-1,0.3-1.9-0.4-2.3-2.1c-0.5-1.7,0-2.8,1-3c1-0.3,1.9,0.4,2.4,2.1
    C18.3,10.6,17.8,11.7,16.8,11.9
    M18.9,8.6c-0.5-1.7-1.9-2.5-3.6-2c-1.7,0.5-2.5,1.9-2,3.6c0.5,1.7,1.9,2.5,3.6,2.1
    C18.6,11.8,19.4,10.3,18.9,8.6
    M26,2.9c-0.7-1.3-2.3-0.2-1.3,0.8c3.1,3,1.5,7.7-1.6,7.7c-1.5,0-2.3-1.1-2.3-2.3
    c0-1.7,1.3-2.8,2.7-3.6c0.9-0.5,0.6-1.4-0.3-1.1C22,4.6,21.4,3,19.7,3c-1.4,0-2.2,0.9-2.2,1.8c0,0.7,0.5,1.6,1.2,1.6
    c0.4,0,0.7-0.3,0.7-0.7c0-0.5-0.5-0.6-0.9-0.8c-1.1-0.4-0.5-1.4,0.5-1.2c1.7,0.2,2.4,2,4.1,1.4l0,0.1c-1.4,0.7-3.7,2.1-3.7,4.2
    c0,1.6,1.2,2.9,2.8,2.9C26.4,12.3,28.1,6.6,26,2.9
    M50.3,9.4c-0.1,2.4-2.1,2.6-3.7,2.3l1.2-4.5C49.3,7.4,50.3,7.9,50.3,9.4
    M47.4,4.2l-1.8,6.5c-0.2,0.7-0.4,1-1,1H44c-0.6,0-0.6-0.4-0.4-1.1l2-7c0.2-0.7,0.6-0.8,1-0.8H47C47.8,2.9,47.7,3.4,47.4,4.2
    M52.7,4.4c0-5.5-12.5-4.2-13.3,0.7c0,0.1,0,0.2,0,0.3c0,0.5,0.2,0.9,0.7,0.9c0.4,0,0.7-0.3,0.7-0.8c0-0.4-0.3-0.8-0.3-1.2
    c0-3.1,10.7-5,10.9-0.1C51.6,5.7,50.4,7,48.9,7c-0.3,0-0.6,0-0.9-0.1l0.8-3.3C49,2.9,49.4,3,49.9,3V2.6h-5.3c-0.5,0-0.6,0.3-0.8,0.7
    c-0.3,0.5-1.2,2.3-2.4,4c-0.3,0.5-1.9,2.9-2.3,2.9c-0.1,0-0.2-0.1-0.2-0.2L38,3.1c0-0.3-0.1-0.4-0.5-0.4h-3.4V3h0.5
    c1.1,0,1.6,0.1,1.3,1.5c-0.6,4-4,11.3-8.9,11.3c-1,0-2.2-0.5-2.2-1.8c0-0.7,0.5-1,1.1-1c0.3,0,0.6,0.1,0.8,0.1
    c0.3,0,0.6-0.3,0.6-0.6c0-0.5-0.6-0.7-1-0.7c-1.1,0-2,0.9-2,2c0,1.5,1.4,2.2,2.8,2.2C32.7,16,36,8.4,37,3.9h0l0.8,7.9
    c0,0.4,0.4,0.5,0.7,0.2l5.9-8.4l0,0l-1.9,6.9c-0.3,1-0.7,1.2-1.7,1.2v0.3c2.3,0,4.6,0,6.9,0c1.8,0,3.9-0.7,3.9-2.9
    c0-1.1-0.8-1.8-1.8-2.1C51.5,7.2,52.7,5.9,52.7,4.4
    M58.3,4.8c-0.4,1.8-1.9,2.4-3.6,2L55.9,3C57.3,2.7,58.7,3.1,58.3,4.8 M59.7,4.6
    c0-2.5-3.1-1.9-4.3-1.9c-1,0-1.6,0-2.3,0V3c0.3,0,1.7-0.1,1.5,0.7L52.2,11c-0.3,0.9-0.7,0.9-1.5,0.9v0.3c1.3,0,2.5,0,3.8,0v-0.3
    c-0.7,0-1.3-0.1-1-1l1.2-3.6c0.9,0.2,1.1,0.4,1.4,1.2c1.1,2.2,1.6,6.7,6,6.7c1.5,0,2.9-1.1,2.9-1.9c0-0.3-0.3-0.5-0.5-0.5
    c-0.2,0-0.4,0.2-0.5,0.4c-0.4,0.7-0.7,1.7-2.2,1.7c-3.2,0-3.5-4.1-4.2-6.4c-0.2-0.7-0.6-1-1.1-1.2C58,7.2,59.7,6.3,59.7,4.6" />
  <path
    d="M30.6,11.8c-1.5,0-2.5-1.5-2.5-2.9c0-0.8,0.3-1.4,0.7-1.9c1.2,1.2,3.6,0.9,3.1-0.4C31.8,6.2,31.2,6,30.6,6
    c-0.5,0-1,0.1-1.5,0.3l0.1,0.3c0.4-0.2,0.8-0.4,1.3-0.4c0.5,0,0.8,0.1,0.9,0.4c0.1,0.8-1.6,0.9-2.2,0l-0.1-0.3c0-0.1,0-0.2,0-0.3
    c0-2.7,2.7-3.7,3.5-2.8c0.5,0.5-0.1,1-0.1,1.5c0,0.7,1.2,0.8,1.2-0.3c0-1.1-1-1.9-2.3-1.9c-1.6,0-3.2,1.1-3.2,2.7
    c0,0.6,0.2,1.1,0.4,1.4c0,0,0,0,0,0c-1,0.7-1.7,1.8-1.7,3c0,1.6,1.5,2.7,3,2.7c2.6,0,4-2.4,3.9-4.4c0-1-1.1-0.8-1.1-0.1
    c0,0.4,0.2,0.8,0.2,1.4C32.8,10.6,32,11.8,30.6,11.8" />
  <path
    d="M62.5,11.8c-1.5,0-2.5-1.5-2.5-2.9c0-0.8,0.3-1.4,0.7-1.9c1.2,1.2,3.6,0.9,3.1-0.4C63.7,6.2,63.1,6,62.6,6
    c-0.5,0-1,0.1-1.5,0.3l0.1,0.3c0.4-0.2,0.8-0.4,1.3-0.4c0.5,0,0.8,0.1,0.9,0.4c0.1,0.8-1.6,0.9-2.2,0l-0.1-0.3c0-0.1,0-0.2,0-0.3
    c0-2.7,2.7-3.7,3.5-2.8c0.5,0.5-0.1,1-0.1,1.5c0,0.7,1.2,0.8,1.2-0.3c0-1.1-1-1.9-2.3-1.9c-1.6,0-3.2,1.1-3.2,2.7
    c0,0.6,0.2,1.1,0.4,1.4c0,0,0,0,0,0c-1,0.7-1.7,1.8-1.7,3c0,1.6,1.5,2.7,3,2.7c2.6,0,4-2.4,3.9-4.4c0-1-1.1-0.8-1.1-0.1
    c0,0.4,0.2,0.8,0.2,1.4C64.7,10.6,64,11.8,62.5,11.8" />
</svg>
